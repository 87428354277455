<template>
  <div class="" style="width: 100%; margin-bottom: 100px;">
    <v-container>
      <v-row>
        <v-col class='d-flex flex-row align-center'>
          <v-btn fab x-small @click="()=>{this.$router.go(-1)}">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <h1>New Product</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <!--              Primary Information-->
          <v-text-field
              @focus="lockGlobalQueryBc" @blur="unlockGlobalQueryBc"               class="mt-5"
              label="Name"
              outlined
              v-model="data.name"
          />
          <v-text-field
              @focus="lockGlobalQueryBc" @blur="unlockGlobalQueryBc"               label="Model Number"
              outlined
              v-model="data.sku"
          />
          <v-text-field
              @focus="lockGlobalQueryBc" @blur="unlockGlobalQueryBc"               label="Regular Price"
              outlined
              type="number"
              v-model="data.regular_price"
          />
          <v-text-field
              @focus="lockGlobalQueryBc" @blur="unlockGlobalQueryBc"               label="Cost Price (Supplier Price)"
              outlined
              type="number"
              v-model="data.cost_price"
          />
          <v-text-field
              @focus="lockGlobalQueryBc" @blur="unlockGlobalQueryBc"               label="Promotion Price (Sale Price)"
              outlined
              type="number"
              v-model="data.sale_price"
          />
          <div>
            <v-row>
              <v-col>
                <span class="d-flex flex-row align-center">
                  <h2>Supplier</h2>
                  <span>
                    <v-btn v-if="isAllowed('supplier','c')" @click="createSupplierDialog=true" style="margin-left: 10px;" fab color="info" x-small><v-icon>mdi-plus</v-icon></v-btn>
                    <v-btn @click="suppliersDialog=true" style="margin-left: 10px;" fab color="info" x-small><v-icon>mdi-chevron-right</v-icon></v-btn>
                  </span>
                </span>
              </v-col>
            </v-row>
            <v-select
                v-if="data.supplierId"
                clearable
                v-model="data.supplierId"
                :items="this.selectedSupplier"
                :item-text="item => `${item.name}${item.address?' - '+item.address:''}${item.phone?' - '+item.phone:''}`"
                item-value="id"
                outlined
                label="Supplier"
                @click:clear="selectedSupplier=[]"
            />
          </div>
          <div class="mb-6">
            <v-row>
              <v-col>
                <span class="d-flex flex-row align-center">
                  <h2>Brand</h2>
                  <span>
                    <v-btn v-if="isAllowed('brand','c')" @click="createBrandDialog=true" style="margin-left: 10px;" fab color="info" x-small><v-icon>mdi-plus</v-icon></v-btn>
                    <v-btn @click="brandsDialog=true" style="margin-left: 10px;" fab color="info" x-small><v-icon>mdi-chevron-right</v-icon></v-btn>
                  </span>
                </span>
              </v-col>
            </v-row>
            <v-select
                v-if="data.brandId"
                clearable
                v-model="data.brandId"
                :items="this.selectedBrand"
                item-text="name"
                item-value="id"
                outlined
                label="Brand"
                @click:clear="selectedBrand=[]"
            />
          </div>
          <v-radio-group
              label="Type"
              v-model="data.type"
              mandatory
              row
              @change="checkIfVariationsExist"
          >
            <v-radio
                :key=i v-for="(type, i) in productTypeValues"
                :label="type.name"
                :value="type.value"
            />
          </v-radio-group>

          <div v-if="data.type==='variable'">
            <!--            Attributes-->
            <v-row><v-col><h2>Attributes</h2></v-col></v-row>
            <v-row>
              <v-col>
                <v-btn class="mx-2" @click="isAddingAttribute=!isAddingAttribute">{{isAddingAttribute ? "Cancel" : "Create New Attribute"}}</v-btn>
                <v-card outlined class="spacedCard" v-if="isAddingAttribute">
                  <v-text-field
                      @focus="lockGlobalQueryBc" @blur="unlockGlobalQueryBc"                       label="Name"
                      outlined
                      v-model="customAttributeName"
                  />
                  <v-combobox
                      multiple
                      outlined
                      v-model="customAttributeValues"
                      label="Values"
                      chips
                      deletable-chips
                      clearable
                      persistent-hint
                      hint="Press Enter to separate values."
                  />
                  <v-btn class="mx-2" :disabled="customAttributeName.length===0" @click="saveAttribute">Save Attribute</v-btn>
                  <v-progress-circular
                      indeterminate
                      color="green"
                      v-if="attrLoading"
                  ></v-progress-circular>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                    v-model="selectedAttributes"
                    item-text="name"
                    return-object
                    :items="attributes"
                    label="Attributes"
                    multiple
                    outlined
                    chips
                    clearable
                    deletable-chips
                    @change="generateAttributes"
                />
                <v-expansion-panels>
                  <v-expansion-panel v-for="(attribute,i) in generatedAttributes" :key="i">
                    <v-expansion-panel-header>
                      <b>{{attribute.name}}</b>
                      <span>
                      <span v-for="(termName, j) in generatedAttributes[i].options" :key="j">{{j>0? "   |   " : ""}}{{termName}}</span>
                    </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col>
                          <v-btn class="mx-2" @click="customAttributeTerms[i].visible=!customAttributeTerms[i].visible">{{customAttributeTerms[i].visible ? "Cancel" : "Create New Terms"}}</v-btn>
                          <v-card outlined class="spacedCard" v-if="customAttributeTerms[i].visible">
                            <v-combobox
                                multiple
                                outlined
                                v-model="customAttributeTerms[i].values"
                                label="Values"
                                chips
                                deletable-chips
                                clearable
                                persistent-hint
                                hint="Press Enter to separate values."
                            />
                            <v-btn class="mx-2" :disabled="customAttributeTerms[i].values.length===0" @click="saveAttributeTerms(i)">Save Terms</v-btn>
                            <v-progress-circular
                                indeterminate
                                color="green"
                                v-if="attribute.loading"
                            ></v-progress-circular>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-autocomplete
                              v-model="generatedAttributes[i].options"
                              :items="attribute.previewOptions"
                              :label="attribute.name"
                              multiple
                              outlined
                              chips
                              clearable
                              deletable-chips
                              @change="checkIfVariationsExist"
                          />
                          <v-checkbox
                              v-model="generatedAttributes[i].visible"
                              label="Visible?"
                              outlined
                          />
                          <v-checkbox
                              v-if="data.type==='variable'"
                              v-model="generatedAttributes[i].variation"
                              label="Used for Variations"
                              outlined
                              @change="checkIfVariationsExist"
                          />
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>

            <!--            Variations-->
            <v-row><v-col><h2>Variations</h2></v-col></v-row>
            <v-row>
              <v-col v-if="isThereVariations">
                <v-btn class="mx-2" @click="createVariation">Add Variation</v-btn>
              </v-col>
              <v-col v-else>
                <span>Your product type need to be <b>Variable</b> and you need to have at least one attribute selected with <b>Used For Variations</b> enabled.</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-expansion-panels>
                  <v-expansion-panel v-for="(item,i) in variations" :key="i">
                    <v-expansion-panel-header>
                      <v-container>
                        <b>
                          <span :key="k" v-for="(attr, k) in item.attributes">{{k>0? "   |   " : ""}}{{attr.name}}: {{attr.option ? attr.option : "No Value Selected"}}</span>
                        </b>
                      </v-container>
                      <v-btn class="mx-2" style="float: right" @click.native.stop="removeVariation(i)"><v-icon>mdi-delete-forever</v-icon></v-btn>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <h3>Attributes</h3>
                      <v-select
                          v-for="(attr, j) in variationAttributes"
                          :key="j"
                          :items="attr.values"
                          :label="attr.name"
                          outlined
                          clearable
                          v-model="variations[i].attributes[j].option"
                          @change="updateVariationAttribute($event, i, j)"
                      />
                      <h3>General</h3>
                      <v-text-field
                          @focus="lockGlobalQueryBc" @blur="unlockGlobalQueryBc"                           label="SKU"
                          outlined
                          v-model="variations[i].sku"
                      />
                      <v-text-field
                          @focus="lockGlobalQueryBc" @blur="unlockGlobalQueryBc"                           label="Regular Price"
                          outlined
                          type="number"
                          v-model="variations[i].regular_price"
                      />
                      <v-text-field
                          @focus="lockGlobalQueryBc" @blur="unlockGlobalQueryBc"                           label="Cost Price (Supplier Price)"
                          outlined
                          type="number"
                          v-model="variations[i].cost_price"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <hr>
      <br>
      <v-row>
        <v-col>
          <v-btn class="mx-2" color="success" @click="create" :disabled="!this.data.name">Create</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog scrollable v-model="suppliersDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Suppliers
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <v-text-field @focus="lockGlobalQueryBc" @blur="unlockGlobalQueryBc" v-model="supplierValue" @input="searchSuppliers" label="Search Suppliers" outlined></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-card @click="setSupplier(supplier)" :key="i" v-for="(supplier, i) in suppliersFound">
            <span class="d-flex flex-column justify-start" style="text-align: left; padding: 20px;">
              <h3>{{supplier.name}}</h3>
              <p v-if="supplier.address">{{supplier.address}}</p>
              <p v-if="supplier.email">{{supplier.email}}</p>
            </span>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeSuppliersDialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="brandsDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Brands
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <v-text-field @focus="lockGlobalQueryBc" @blur="unlockGlobalQueryBc" v-model="brandValue" @input="searchBrands" label="Search Brands" outlined></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-card @click="setBrand(brand)" :key="i" v-for="(brand, i) in brandsFound">
            <span class="d-flex flex-column justify-start" style="text-align: left; padding: 20px;">
              <h3>{{brand.name}}</h3>
            </span>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeBrandsDialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="createSupplierDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Create Supplier
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <v-text-field @focus="lockGlobalQueryBc" @blur="unlockGlobalQueryBc" @input="resetExisting" :rules="nameRules" v-model="newSupplier.name" label="Company Name" outlined></v-text-field>
          <v-text-field @focus="lockGlobalQueryBc" @blur="unlockGlobalQueryBc" @input="resetExisting" v-model="newSupplier.email" label="Email" outlined></v-text-field>
          <v-text-field @focus="lockGlobalQueryBc" @blur="unlockGlobalQueryBc" @input="resetExisting" v-model="newSupplier.phone" label="Phone" outlined></v-text-field>
          <div v-if="existingData.length>0">
            <p class="red--text">Similar Suppliers already exist. Are you sure you want to create another?</p>
            <h3>Or Choose A Similar Supplier</h3>
            <v-btn class="mx-1 my-1" color="info" @click="existingSupplierSelected(item)" v-for="(item, i) in existingData" :key="i">{{item.name}}</v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeCreateSupplier">
            Cancel
          </v-btn>
          <v-btn v-if="existingData.length===0" :disabled="!newSupplier.name" class="primary" text @click="createSupplier">
            Create
            <v-progress-circular
                indeterminate
                color="green"
                v-if="supplierLoading"
                style="margin-left: 10px;"
            ></v-progress-circular>
          </v-btn>
          <v-btn v-else class="primary" text @click="confirmCreateSupplier">
            Create New
            <v-progress-circular
                indeterminate
                color="green"
                v-if="supplierLoading"
                style="margin-left: 10px;"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="createBrandDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Create Brand
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <v-text-field @focus="lockGlobalQueryBc" @blur="unlockGlobalQueryBc" @input="resetExisting" :rules="nameRules" v-model="newBrand.name" label="Name" outlined></v-text-field>
          <div v-if="existingData.length>0">
            <p class="red--text">Similar Brands already exist. Are you sure you want to create another?</p>
            <h3>Or Choose A Similar Brand</h3>
            <v-btn class="mx-1 my-1" color="info" @click="existingBrandSelected(item)" v-for="(item, i) in existingData" :key="i">{{item.name}}</v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeCreateBrand">
            Cancel
          </v-btn>
          <v-btn v-if="existingData.length===0" :disabled="!newBrand.name" class="primary" text @click="createBrand">
            Create
            <v-progress-circular
                indeterminate
                color="green"
                v-if="brandLoading"
                style="margin-left: 10px;"
            ></v-progress-circular>
          </v-btn>
          <v-btn v-else class="primary" text @click="confirmCreateBrand">
            Create New
            <v-progress-circular
                indeterminate
                color="green"
                v-if="brandLoading"
                style="margin-left: 10px;"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<style>
.spacedCard{
  padding: 20px;
  margin-bottom: 20px;
}
</style>

<script>
import axios from 'axios';
import { mapMutations , mapGetters} from "vuex";
export default {
  data () {
    return {
      snackObj: {
        state: false,
        color: '',
        text: ''
      },

      loader: false,
      max25chars: v => v.length <= 25 || 'Input too long!',
      data: {
        type: "simple",
        dimensions: {
          length: "",
          width: "",
          height: ""
        }
      },
      productTypeValues: [
        {name: "Simple", value: "simple"}, {name: "Variable", value: "variable"}
      ],
      attrLoading: false,
      selectedAttributes: [],
      generatedAttributes: [],

      isThereVariations: false,
      variations: [],
      variationAttributes: [],

      isAddingAttribute: false,
      customAttributeName: "",
      customAttributeValues: [],

      customAttributeTerms: [],

      categories: [],
      tags: [],
      images: [],
      attributes: [],
      brands: [],
      suppliers: [],

      products: [],

      suppliersDialog: false,
      supplierValue: "",
      suppliersFound: [],
      selectedSupplier: [],
      supplierLoading: false,

      createSupplierDialog: false,
      newSupplier: {},

      brandsDialog: false,
      brandValue: "",
      brandsFound: [],
      selectedBrand: [],
      brandLoading: false,

      createBrandDialog: false,
      newBrand: {},

      nameRules: [
        v => !!v || 'Name is required'
      ],

      existingData: [],

      singular: "Inventory",
      singularLower: "inventory",
      plural: "Inventories",
      pluralLower: "inventories"
    }
  },
  async mounted(){
    await this.getAllData();
  },
  computed: {
    ...mapGetters(['getEndpoint', 'getId', "isAllowed"])
  },
  methods: {
    ...mapMutations([
      'lockGlobalQueryBc',
      'unlockGlobalQueryBc',
    ]),
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    },
    async create(){
      try {
        this.data.attributes = this.generatedAttributes;
        this.data.variations = this.variations;

        let obj = {
          ...this.data
        };

        //cleaning up jsonb object
        obj.attributes.forEach(attribute => {
          delete attribute.loading;
          delete attribute.previewOptions;
        });

        let res = await axios.post(`${this.getEndpoint}/api/products`, obj)
        if(res.data.error) throw res.data.error

        this.snack("Product Created", "success");
        await this.$router.push({path: `/products/view/${res.data.data.id}`});
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      }
    },
    async saveAttribute(){
      try{
        this.attrLoading = true;
        let existingAttr = this.attributes.find(attr => {
          return attr.name.toLowerCase()===this.customAttributeName.toLowerCase();
        });

        if(existingAttr){
          alert("Attribute already exists with this name.")
          return;
        }

        let terms = [];
        this.customAttributeValues.forEach(term => {
          terms.push({name: term});
        });

        let attr = {
          name: this.customAttributeName,
          ProductAttributeTerms: terms
        }

        let createdAttr = await axios.post(`${this.getEndpoint}/api/productattributes`, {name: attr.name});
        if(createdAttr.data.error) throw createdAttr.data.error

        attr.id = createdAttr.data.data.id;

        let createdTerms = await axios.post(`${this.getEndpoint}/api/productattributeterms/bulkcreate`, {attributeId: attr.id, terms: terms});
        if(createdTerms.data.error) throw createdTerms.data.error

        this.customAttributeValues = [];
        this.customAttributeName = "";
        this.isAddingAttribute = false;

        this.attributes.push(attr);
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      }
      finally {
        this.attrLoading = false;
      }
    },
    async saveAttributeTerms(attrIndex){
      try{
        this.generatedAttributes[attrIndex].loading = true;

        for(let newTerm of this.customAttributeTerms[attrIndex].values){
          let existingTerm = this.generatedAttributes[attrIndex].previewOptions.find(term => {
            return term.toLowerCase()===newTerm.toLowerCase();
          });

          if(existingTerm) throw `The term '${existingTerm}' already exists in '${this.generatedAttributes[attrIndex].name}'`;
        }

        let terms = [];
        this.customAttributeTerms[attrIndex].values.forEach(t => {
          terms.push({name: t});
        });

        let createdTerms = await axios.post(`${this.getEndpoint}/api/productattributeterms/bulkcreate`, {attributeId: this.generatedAttributes[attrIndex].id, terms: terms});
        if(createdTerms.data.error) throw createdTerms.data.error

        this.generatedAttributes[attrIndex].previewOptions = this.generatedAttributes[attrIndex].previewOptions.concat(this.customAttributeTerms[attrIndex].values);

        this.customAttributeTerms[attrIndex].values=[];
        this.customAttributeTerms[attrIndex].visible=false;
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      }
      finally {
        this.generatedAttributes[attrIndex].loading = false;
      }
    },
    generateAttributes(n){
      let genAttrs = [];
      let genAttrTermsCreate = [];
      let genVariationAttributes = [];

      n.forEach((attr, index) => {
        let attrObj = this.generatedAttributes.find(attrData => {
          return attrData.name===attr.name;
        });

        if(!attrObj){
          attrObj = {
            id: attr.id,
            name: attr.name,
            position: index,
            previewOptions: [],
            loading: false,
            options: [],
            visible: true,
            variation: true
          };
          attr.ProductAttributeTerms.forEach(term => {
            attrObj.previewOptions.push(term.name)
          });
        }

        if(attrObj.variation===true){
          genVariationAttributes.push({
            name: attrObj.name,
            values: attrObj.options
          });
        }

        genAttrs.push(attrObj);

        let termObj = this.customAttributeTerms.find(termData => {
          return termData.name===attr.name;
        });

        if(!termObj){
          termObj = {
            name: attr.name,
            values: [],
            visible: false
          };
        }

        genAttrTermsCreate.push(termObj);
      });

      this.generatedAttributes = genAttrs;
      this.customAttributeTerms = genAttrTermsCreate;
      this.variationAttributes = genVariationAttributes;

      this.checkIfVariationsExist();
    },
    checkIfVariationsExist(){
      for(let attr of this.generatedAttributes){
        if(this.data.type==="variable") {
          if (attr.variation === true) {
            this.isThereVariations = true;
            this.updateVariationTerms();
            return;
          }
        }
        else{
          attr.variation = false;
        }
      }
      this.isThereVariations = false;
    },
    updateVariationTerms(){
      let genVariationAttributes = [];

      this.generatedAttributes.forEach(attrObj => {
        if(attrObj.variation===true){
          genVariationAttributes.push({
            id: attrObj.id,
            name: attrObj.name,
            values: attrObj.options
          });
        }
      });

      this.variations.forEach(variation => {
        for(let i=variation.attributes.length; i>=0; --i){
          let existingAttr = genVariationAttributes.find(attr => {
            if(variation.attributes[i]){
              return attr.name===variation.attributes[i].name;
            }
            return false;
          });

          if(!existingAttr){
            variation.attributes.splice(i, 1);
          }
          else{
            if(this.generatedAttributes[i]){
              let existingTerm = this.generatedAttributes[i].options.find(term => {
                return term===variation.attributes[i].option;
              });

              if(!existingTerm){
                variation.attributes[i].option = null;
              }
            }
          }

          genVariationAttributes.forEach(attr => {
            let existingInVariation = variation.attributes.find(varAttr => {
              return varAttr.name===attr.name;
            });

            if(!existingInVariation){
              variation.attributes.push({
                name: attr.name,
                option: null
              });
            }
          });
        }
      });

      this.variationAttributes = genVariationAttributes;

      this.$forceUpdate();
    },
    createVariation(){
      let variation = {
        type: "variation",
        dimensions: {
          length: "",
          width: "",
          height: ""
        },
        image: {},
        attributes: [],
        metadata: {}
      };

      this.variationAttributes.forEach(attr => {
        variation.attributes.push({
          id: attr.id,
          name: attr.name,
          option: null
        });
      });

      this.variations.push(variation);
    },
    generateAllVariations(){

    },
    removeVariation(i){
      this.variations.splice(i, 1);
    },
    updateVariationAttribute(data, vIndex, aIndex){
      this.variations[vIndex].attributes[aIndex] = {
        id: this.variationAttributes[aIndex].id,
        name: this.variationAttributes[aIndex].name,
        option: data
      };

      this.$forceUpdate();
    },
    async getAllData(){
      try {
        let res = await axios.get(`${this.getEndpoint}/api/products/externaldata`);
        if(res.data.error) throw res.data.error
        this.categories = res.data.data.categories;
        this.tags = res.data.data.tags;
        this.attributes = res.data.data.attributes;
        this.products = res.data.data.products;
        let suppliers = await axios.get(`${this.getEndpoint}/api/suppliers`)
        if(res.data.error) throw res.data.error
        this.suppliers = suppliers.data.data;
        let brands = await axios.get(`${this.getEndpoint}/api/brands`)
        if(res.data.error) throw res.data.error
        this.brands = brands.data.data;
      } catch (error) {
        console.error(error);
        this.snack(error.msg || error, "error");
      } finally {
        this.loader = false;
      }
    },
    searchSuppliers(){
      if(!this.supplierValue){
        this.suppliersFound = [];
        return;
      }

      let suppliers = this.suppliers.filter(supplier => {

        return supplier.name.toLowerCase().includes(this.supplierValue.toLowerCase());
      });

      this.suppliersFound = suppliers || [];
    },
    closeSuppliersDialog(){
      this.suppliersDialog = false;
      this.supplierValue = "";
      this.suppliersFound = [];
    },
    setSupplier(supplier){
      this.data.supplierId = supplier.id;
      this.selectedSupplier = [supplier]
      this.closeSuppliersDialog();
    },
    closeCreateSupplier(){
      this.createSupplierDialog = false;
      this.newSupplier = {};
      this.supplierLoading = false;
      this.existingData = [];
    },
    async createSupplier(){
      try{
        this.supplierLoading = true;
        let res = await axios.post(`${this.getEndpoint}/api/suppliers`, this.newSupplier)
        if(res.data.error) throw res.data.error

        if(res.data.data.created){
          this.setSupplier(res.data.data.data)
          this.snack("Supplier Created", "success");
          let suppliers = await axios.get(`${this.getEndpoint}/api/suppliers/lite`)
          if(suppliers.data.error) throw suppliers.data.error
          this.suppliers = suppliers.data.data;
          this.closeCreateSupplier();
        }
        else{
          this.existingData = res.data.data.data;
          this.snack("Similar Suppliers Found.");
        }
      }
      catch (error){
        console.error(error);
        this.snack(error.msg || error, "error");
      }
      finally {
        this.supplierLoading = false;
      }
    },
    existingSupplierSelected(supplier){
      this.setSupplier(supplier);
      this.closeCreateSupplier();
    },
    async confirmCreateSupplier(){
      try {
        this.supplierLoading = true;
        let res = await axios.post(`${this.getEndpoint}/api/suppliers/confirmCreate`, this.newSupplier)
        if(res.data.error) throw res.data.error

        this.setSupplier(res.data.data)
        this.snack("Supplier Created", "success");
        let suppliers = await axios.get(`${this.getEndpoint}/api/suppliers/lite`)
        if(suppliers.data.error) throw suppliers.data.error
        this.suppliers = suppliers.data.data;
        this.closeCreateSupplier();
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      }
      finally {
        this.supplierLoading = false;
      }
    },

    searchBrands(){
      if(!this.brandValue){
        this.brandsFound = [];
        return;
      }

      let brands = this.brands.filter(brand => {
        return brand.name.toLowerCase().includes(this.brandValue.toLowerCase());
      });

      this.brandsFound = brands || [];
    },
    closeBrandsDialog(){
      this.brandsDialog = false;
      this.brandValue = "";
      this.brandsFound = [];
    },
    setBrand(brand){
      this.data.brandId = brand.id;
      this.selectedBrand = [brand]
      this.closeBrandsDialog();
    },
    closeCreateBrand(){
      this.createBrandDialog = false;
      this.newBrand = {};
      this.brandLoading = false;
      this.existingData = [];
    },
    async createBrand(){
      try{
        this.brandLoading = true;
        let res = await axios.post(`${this.getEndpoint}/api/brands`, this.newBrand)
        if(res.data.error) throw res.data.error

        if(res.data.data.created){
          this.setBrand(res.data.data.data)
          this.snack("Brand Created", "success");
          let brands = await axios.get(`${this.getEndpoint}/api/brands/lite`)
          if(res.data.error) throw res.data.error
          this.brands = brands.data.data;
          this.closeCreateBrand();
        }
        else{
          this.existingData = res.data.data.data;
          this.snack("Similar Brands Found.");
        }
      }
      catch (error){
        console.error(error);
        this.snack(error.msg || error, "error");
      }
      finally {
        this.brandLoading = false;
      }
    },
    existingBrandSelected(brand){
      this.setBrand(brand);
      this.closeCreateBrand();
    },
    async confirmCreateBrand(){
      try {
        this.brandLoading = true;
        let res = await axios.post(`${this.getEndpoint}/api/brands/confirmCreate`, this.newBrand)
        if(res.data.error) throw res.data.error

        this.setBrand(res.data.data)
        this.snack("Brand Created", "success");
        let brands = await axios.get(`${this.getEndpoint}/api/brands/lite`)
        if(res.data.error) throw res.data.error

        this.brands = brands.data.data;
        this.closeCreateBrand();
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      }
      finally {
        this.brandLoading = false;
      }
    },
    resetExisting(){
      this.existingData = [];
    }
  }
}
</script>